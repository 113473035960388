import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { AiOutlineCaretRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";

import { Link } from "react-router-dom";
import Navbar from "./components/Navbar";
const Result = () => {
  return <p>you message has been sent, i will contact you soon </p>;
};
const OnlineCoaching2 = () => {
  const [result, setResult] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_b3p32da",
        "template_gvwhroa",
        form.current,
        "user_vd1oAn8TKrnbuZlTd4J59"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };
  //hide result
  setTimeout(() => {
    setResult(false);
  }, 3000);
  return ( 
    <>  <Navbar mode={1}/>
    <div className="onlinecoaching">
     
      <div>
  
      <Link to="/"><AiOutlineArrowLeft fill="#c79f5a" size="1.5em" className="back"/> </Link>  

 <h1 className="program-title">Online Coaching</h1>

</div>

    

      <img className="pattern" src="./icon2.png" alt="" />
      <div className="program-page-design">
        <div className="program-page">
          <div className="program-page-image">
            <div className="image-onlinecoaching" />
          </div>

          <div className="program-page-description">
            <h2>Program Description</h2>
            <p>
             Start your fitness journey on the right foot by booking a personalized live training regiment from anywhere in the World!
             <br/>I will build a personalized workout routine and nutrition plan specifically for YOU and YOUR GOALS. 
            </p>
            <h2>What's Included</h2>

            <ul>
            <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Customised plan that works for your body and adjusts with your schedule.  
              </li>
            
              <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Video demos for every exercice,shared on an app. 
              </li>
              <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Customised diet plan.  
              </li>
              <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Customised training program   ( beginners , intermediates , advanced ). 

              </li>
              <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Customised supplements plan.
              </li>
              <li>
                <AiOutlineCaretRight fill="#c79f5a" size="1.5em" /> Weekly check-ins & Updates through WhatsApp.
              </li>
            </ul>

            <form ref={form} onSubmit={sendEmail}>
              <FormControl
                variant="standard"
                sx={{ m: 1, mt: 3, width: "70ch" }}
                style={{ paddingTop: "12px" }} 
                className="MuiInput-custom"
              >
                <InputLabel
                  className="InputLabel"
                  style={{ padding: "4px 6px 4px 20px" }}
                  required
                >
                  Your email
                </InputLabel>
                <Input
                  style={{
                    padding: "0px 10px 0px",
                  }}
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  name="email"
                />
              </FormControl>
              <input
                type="text"
                value="online coaching"
                name="subject"
                className="hideInput"
              />
              <div className="program-request">
                <input
                  type="submit"
                  value="Send message"
                  className="submit"
                />
              </div>
              <div>{result ? <Result /> : null}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
   
  );
};

export default OnlineCoaching2;
