
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { AiOutlineArrowLeft} from "react-icons/ai";
import { IoRocket } from "react-icons/io5";

import { Link } from "react-router-dom";
import Navbar from "./components/Navbar";

const GuideEbook = () => {
 
  return (
    <>  <Navbar mode={1}/>
    <div className="onlinecoaching">
      <div>
     
 <Link to="/"><AiOutlineArrowLeft fill="#c79f5a" size="1.5em" className="back"/> </Link>  
 <h1 className="program-title diet">12-week Guide E-book</h1>

</div>
   

      <img className="pattern" src="./icon2.png" alt="" />
      <div className="program-page-design">
        <div className="program-page">
          <div className="program-page-image">
            <div className="image-guide-ebook" />
          </div>

          <div className="program-page-description">

            <div className="coming-soon">
              <div>
                <IoRocket fill="#c79f5a" size="5em" /> 
              </div>
              <div>
                <h1>Coming soon</h1>
              </div>
            </div>
   
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default GuideEbook;