import React, { useEffect, useState } from "react";

import { Link } from "react-scroll";
import * as FaIcons from "react-icons/fa";

import logo from "../images/logo.png";
import fahmibfit from "../images/fahmibfit.png";

import "../Styles/NavBar.css";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 80;
      if (isTop !== true) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const showSidebar = () => setSidebar(!sidebar);
 
  return (
    
    <div
      className={scrolled ? "navbar-dreamhouse-scrolled" : "navbar-dreamhouse"}
    >
      <div className="navb">

           {props.mode==1?
           <> <NavLink to="/">
          <img className="logo" src={logo} alt="" />
          <img src={fahmibfit} alt="" />
        </NavLink>
        <div>
          <Link to="programs" className="navi" activeClass="active">
            Programs
          </Link>
          <Link to="aboutUs" className="navi" activeClass="active">
            About us
          </Link>
          <Link to="contact" className="navi" activeClass="active">
            Contact
          </Link>
        </div>
        </>:<> <NavLink to="/">
          <img className="logo" src={logo} alt="" />
          <img src={fahmibfit} alt="" />
        </NavLink>
        <div>
          <NavLink to="/programs" className="navi" activeClass="active">
            Programs
          </NavLink>
          <NavLink to="/aboutUs" className="navi" activeClass="active" >
            About me
          </NavLink>
          <NavLink to="/contact" className="navi" activeClass="active">
            Contact
          </NavLink>
          </div></>}
        <button className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </button>
      </div>

      <div className={`drawer ${sidebar && "drawer-is-open"}`}>
        <ul>
          <li>
            <Link to="programs" className="navi" onClick={showSidebar}>
              Programs
            </Link>
          </li>
          <li>
            <Link to="aboutUs" className="navi" onClick={showSidebar}>
              About me
            </Link>
          </li>
          <li>
            <Link to="contact" className="navi" onClick={showSidebar}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
