import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Carousel from "./Carousel";
import Card from "./Card";

import pattern from "../images/patternbaf.png";

import program1 from "../images/online-coaching.jpg";
import program2 from "../images/oto-training.jpg";

import program3 from "../images/diet.jpg";
import program4 from "../images/ebook.jpg";
import patternblue from "../images/patternblue.png";

import "../Styles/Programs.css";

const Programs = () => {
  const [keyword, setKeyword] = useState(0);
 const width = window.innerWidth;
          
 var x=0
 width<800?x=1:x=0
  const cards = [
    {
      key: 1,
      content: (
        <Card
          
          imagen={program1}
          title="Online coaching"
          position="1"
          description="Achieve your goal of getting yourself into shape by making use of our online coaching service."
        />
      ),
      imagen: program1,
    },
    {
      key: 2,
      content: (
        <Card
          
          imagen={program2}
          title="One to One Training"
          position="2"
          description="A series of workouts that will shape your body proportions and get you great results. "
        />
      ),
      imagen: program2,
    },
    {
      key: 3,
      content: (
        <Card
          
          imagen={program3}
          title="Customize diet planner"
          position="3"
          description="The most balanced diet for fat-burning and muscle-growth, suitable for your body type and metabolism."
        />
      ),
      imagen:program3,
    },
    {
      key: 4,
      content:
      
      (
        <Card
          imagen={program4}
          title="12-week Guide E-book"
          position="4"
          description="Training becomes more efficient with the right knowledge! Our E-book will fulfill all of your informational needs."
        />
      ),
      imagen:program4,
    },
    {
      key: 5,
      content: <div />,
    },
  ];

  const getKeyword = (key) => {
    key && setKeyword(key);
  };

  return (
    <div className="section-elements-programs">
      <img src={pattern} alt="" className="pattern" />
      <img src={patternblue} alt="" className="pattern-blue" />
      <div className="element">
        <h1 className="title-programs">Programs</h1>
        <p>
        As a Fitness and Nutrition Coach, my goal is to prove that people of all shapes and sizes can become fit and healthy according to their own personal ideals. All of our training programs guaranteed to help you achieve your fitness goals.
        </p>
        <div className="our-programs-responsive">
          {cards?.map(
            (item, index) =>
              index < 4 && (
                
              
              
              
              
              
              
              
              
              
              
              
                <div className="box-program" key={item.key} aria-hidden="true">
                 
                       <img
                    src={item.imagen}
                    alt="img"
                    onClick={() => getKeyword(item.key)}
                  />
                 
               
                </div> 
                
              )
          )}
        </div>
        <div className="btn-booking">
      
          <Link
            to={
              keyword === x
                ? "/online-coaching"
                : keyword === x+1
                ? "/onetoonetraining"
                : keyword === x+2
                ? "/CustomizeDietPlanner"
                : keyword === x+3
                ? "/GuideEbook"
                : "/online-coaching"
            }
          >
            <button className="btn-programs">
             
              {keyword === x
                ? "Book Online coaching"
                : keyword === x+1
                ? "Book online one to one training"
                : keyword === x+2
               ? "Book Customize diet planner"
                : keyword === x+3
                ? "12-week Guide E-book"
                : "Book Online coaching"}
            </button>
          </Link>
      
        </div>

      </div>
   
      <div className="our-programs">
        <Carousel
          getKeyword={getKeyword}
          cards={cards}
          width="10%"
          margin="0 auto"
          offset={2}
          showArrows={false}
        />
      </div>
    </div>
  );
};

export default Programs;
